import React, { createContext } from 'react';

import { Review } from '~/types/reviews';

import { Brand } from './Vehicle';

type PageData = {
  reviews?: Review[];
  brands?: Brand[];
};

const PageDataContext = createContext<PageData>({
  reviews: [],
  brands: [],
});

interface IProps {
  reviews?: Review[];
  brands?: Brand[];
}

export const PageDataProvider: React.FunctionComponent<IProps> = ({
  children,
  reviews,
  brands,
}) => {
  return (
    <PageDataContext.Provider
      value={{
        reviews,
        brands,
      }}
    >
      {children}
    </PageDataContext.Provider>
  );
};

export default PageDataContext;
